// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-de-index-tsx": () => import("./../../../src/pages/de/index.tsx" /* webpackChunkName: "component---src-pages-de-index-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-us-index-tsx": () => import("./../../../src/pages/en-us/index.tsx" /* webpackChunkName: "component---src-pages-en-us-index-tsx" */),
  "component---src-pages-es-index-tsx": () => import("./../../../src/pages/es/index.tsx" /* webpackChunkName: "component---src-pages-es-index-tsx" */),
  "component---src-pages-fr-index-tsx": () => import("./../../../src/pages/fr/index.tsx" /* webpackChunkName: "component---src-pages-fr-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-it-index-tsx": () => import("./../../../src/pages/it/index.tsx" /* webpackChunkName: "component---src-pages-it-index-tsx" */)
}

